import { Signal } from "@preact/signals";
import { useState, useEffect } from "preact/hooks";

const OAuthSwitcher = ({ statusMessage }: { statusMessage: Signal<string> }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null); // Stores the access token
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false); // Tracks the token's validity

  // Simulates fetching the access token (replace with a real API call)
  const fetchAccessToken = async () => {
    try {
      const response = await fetch("/api/oauth_check-tokens", {
        method: "GET",
        credentials: "include", // Sends cookies with the request
      });

      if (!response.ok) {
        setAccessToken(null);
        setIsTokenValid(false); // Token is invalid or not available
        return;
      }

      const data = await response.json();
      setAccessToken(data.accessToken); // Stores the access token
      setIsTokenValid(!!data.accessToken); // Token is valid if it exists
    } catch (_err) {
      setAccessToken(null);
      setIsTokenValid(false);
    }
  };

  // Fetches the token when the component mounts
  useEffect(() => {
    fetchAccessToken();
  }, []);

  const handleLogin = () => {
    statusMessage.value = "OAuth CheckIn ...";
    window.location.href = "/api/oauth_checkin";
  };

  const handleLogout = () => {
    statusMessage.value = "OAuth CheckOut ...";
    window.location.href = "/api/oauth_checkout";
  };

  return (
    <div class="flex items-center justify-center space-x-0">
      <button
        onClick={handleLogin}
        class={`py-2 px-1.5 rounded-l-xl w-min-[5.5rem] ${isTokenValid ? 'bg-orange-400 bg-opacity-60' : 'bg-slate-300 hover:bg-slate-200'} transition-all duration-300 ease-in-out`}
      >
        <span class="block transition-all duration-300 ease-in-out">
          {isTokenValid ? "CheckedIn" : "CheckIn"}
        </span>
      </button>
      <div class="bg-slate-400 p-1.5 text-white text-[.55rem] flex flex-col rounded-sm justify-center items-center leading-none space-y-[3px]">
        <span>OAuth</span>
        <span>2.0</span>
        <span class="text-[.40rem] mx-0 px-0">(server-side)</span>
      </div>
      <button
        onClick={handleLogout}
        class={`py-2 px-1.5 rounded-r-xl w-min-[5.5rem] ${!isTokenValid ? 'bg-orange-400 bg-opacity-60' : 'bg-slate-300 hover:bg-slate-200'} transition-all duration-300 ease-in-out`}
      >
        <span class="block transition-all duration-300 ease-in-out">
          {!isTokenValid ? "CheckedOut" : "CheckOut"}
        </span>
      </button>
    </div>
  );
};

export default OAuthSwitcher;
